.services_ctr {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: $brandGrey;
  transition: 0.5s all ease-in-out;

  >div {
    position: relative;
    width: 100%;
    height: 100vh;
  }
}

.services_ctr_content,
.services_ctr_mobile-content {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);

  @media(max-width: $tablet) {

  }

  >div {
    position: relative;
    flex: 0 0 33.33333%;
    text-align: center;



    img {
      width: 20vw;


    }

    >div >div {
      position: absolute;
      left: 50%;
      bottom: -80px;
      font-size: 28px;
      text-transform: uppercase;
      transform: translateX(-50%);
    }
  }
}

.services_ctr_content {
  @media(max-width: $tablet) {
    display: none;
  }
}

.services_ctr_mobile-content {
  display: none;

  @media(max-width: $tablet) {
    display: block;
    flex-wrap: wrap;
  }

  >div {
    @media(max-width: $tablet) {
      position: relative;
      flex: 0 0 100%;

      img {
        @media(max-width: $tablet) {
          width: 30vw;
        }
      }

      >div >div {
        @media(max-width: $tablet) {
          position: relative;
          bottom: 0;
          font-size: 14px;
          margin-bottom: 5vh;
        }
      }
    }
  }
}
