.production_ctr {
  min-height: 100vh;

  @media(max-width: $tablet) {
    min-height: calc(100vh + 350px);
  }
}

.production_ctr_close {
  width: 100%;
  text-align: center;

  button {
    margin-left: 0;
  }
}

.production_ctr_description {
  @media(max-width: $tablet) {
    position: absolute;
  }
}

.production_ctr_animation {
  width: 100%;
  text-align: center;
  margin: 100px 0;

  img {
    width: 30%;
  }
}

.production_ctr_text {
  div {
    width: 100%;
    height: calc(100vh - 600px);
    overflow-y: scroll;
  }
}

.production_svg_circle-red {
  fill: $brandRed;
}

.production_svg_circle-grey {
  fill: $brandGrey;
}

.cls-1{font-size:47.21px;}.cls-1,.cls-2,.cls-3{font-family:SpaceMono-Regular, Space Mono;}.cls-2{font-size:40.56px;}.cls-3{font-size:32.45px;}.cls-5{fill:url(#linear-gradient);}.cls-6{fill:url(#linear-gradient-2);}.cls-7{fill:url(#linear-gradient-3);}.cls-8{fill:url(#linear-gradient-4);}.cls-9{fill:url(#linear-gradient-5);}.cls-10{fill:url(#linear-gradient-6);}.cls-11{fill:url(#linear-gradient-7);}.cls-12{fill:url(#linear-gradient-8);}
