.about_ctr {
  width: 100%;
  background-color: $brandGrey;
}

.about_ctr_content {
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 50px 0;

  @media(max-width: $tablet) {
    width: 90%;
    padding: 20px;
  }
}

.about_ctr_close {
  text-align: right;
}

.about_ctr_text {
  text-align: left;
  margin-top: -55px;

  @media(max-width: $tablet) {
    margin-top: 0;
  }

  img {
    width: 250px;

    @media(max-width: $tablet) {
      display: block;
      width: 120px;
      margin-top: -35px;
    }
  }

  div {
    font-size: 2vh;
    margin: 50px 0;

    @media(max-width: $tablet) {
      font-size: 13px;
    }
  }
}

.about_txt_title {
  font-size: 32px;
  line-height: 1.2em;
  text-transform: uppercase;

  @media(max-width: $tablet) {
    font-size: 24px;
  }
}
