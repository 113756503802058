.lang-switch_ctr {
  position: fixed;
  top: 50% !important;
  left: 25px !important;
  width: 50px;
  height: 200px;
  transform: translateY(-50%);
  opacity: 0;
  z-index: 20;

  @media(max-width: $tablet) {
    left: 8px !important;
  }
}

.lang-switch_ctr_content {
  position: relative;
  width: 50px;
  height: 200px;
}

.lang-switch_ctr_txt-lt {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.lang-switch_ctr_slide {
  img {
    width: 8px;
    padding-top: 5px;
  }
}

.lang-switch_ctr_thumb {
  position: fixed;
  bottom: 15px;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translateX(-50%);
  background: {
    position: center;
    size: 30px 30px;
    repeat: no-repeat;
  }
  cursor: pointer;
}

.react-draggable {
  transition: 100ms all ease-in-out;
}
