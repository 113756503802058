html,
body {
  font: 12px $brandText {
    weight: 400;
  };
  color: $black;
  text-align: left;
  margin: 0;
  padding: 0;
  text-rendering: auto;
}

a {
  color: $black;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $brandRed
  }

  &._mod_underline {
    text-decoration: underline;
  }
}

ul {
  margin: 0 0 80px 0;

  li {
    margin: 10px 0;
    padding-left: 50px;

    @media(max-width: $tablet) {
      padding-left: 20px;
    }
  }
}

button {
  font: 14px $brandText;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  vertical-align: 17px;
  margin-left: 50px;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;

  @media(max-width: $tablet) {
    font-size: 10px;
    padding: 7px;
  }

  &._mod_grey {
    color: $brandGrey;
    border: 1px solid $brandGrey;
  }

  &._mod_black {
    color: $black;
    border: 1px solid $black;
  }

  &._mod_transparent {
    background-color: transparent !important;
  }
}

._ele_clickable {
  cursor: pointer;
}
