.service_ctr {
  width: 100%;
  min-height: calc(100vh - 100px);
  background-color: $brandGrey;
  transition: 1s all ease-in-out;
  padding: 50px 0;
  z-index: 111000;
}

.service_ctr_content {
  position: relative;
  width: 80%;
  font-size: 2vh;
  text-align: left;
  margin: 0 auto;
  z-index: 115000;

  @media(max-width: $tablet) {
    width: 100%;
    font-size: 13px;
    margin: 10px auto;
  }
}

.service_ctr_close {
  position: fixed;
  top: 50px;
  left: 80px;
  width: 90%;
  text-align: right;

  @media(max-width: $tablet) {
    top: 20px;
    left: 0;
    width: 97%;
    z-index: 500000;
  }

  button {
    background-color: $brandGrey;
    margin-left: 0;
  }
}

.service_ctr_animation {
  width: 100%;
  text-align: center;
  margin: 60px 0;

  @media(max-width: $tablet) {
    margin: 10px 0;
  }

  svg,
  img {
    height: 40vh;
    overflow: visible;

    @media(max-width: $tablet) {
      width: 95%;
    }
  }
}

.service_ctr_text {
  div {
    width: 100%;

    @media(max-width: $tablet) {
      width: 95%;
    }
  }
}

.service_ctr_gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to right, transparent, transparent);
  z-index: 0;
}

.production_svg_circle-red {

}
