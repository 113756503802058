.home_ctr {
  width: 100%;
}

.home_ctr_top-panel {
  position: relative;
  width: 100%;
  height: 300vh;
  background-color: $brandGrey;
  z-index: -1;
}

.home_ctr_tes {
  position: fixed;
  width: 15%;
  transform: translate(-50%, -50%);

  @media(max-width: $tablet) {
    width: 25%;
  }

  img {
    width: 100%;
  }

  svg path {
    fill: $brandRed;
  }
}

.home_ctr_tes-t {
  left: 30%;
  // margin-top: -30px;

  @media(max-width: $tablet) {
    left: 20%;
  }
}

.home_ctr_tes-e {
  left: 50%;
  // margin-top: -30px;
}

.home_ctr_tes-s {
  left: 70%;
  // margin-top: -30px;

  @media(max-width: $tablet) {
    left: 80%;
  }
}

.home_ctr_tes-full-text {
  position: fixed;
  top: 70%;
  left: 50%;
  width: 200px;
  font-size: 20px;
  text: {
    align: center;
    transform: uppercase;
  }
  letter-spacing: 0.1em;
  // margin-top: 60px;
  transform: translate(-50%, -50%);

  div {
    text-align: center;
  }
}

.home_ctr_fixed-tes-t {
  position: fixed;
  top: 21px !important;
  left: 20px !important;
  width: 4%;

  @media(max-width: $tablet) {
    top: 18px !important;
    left: 18px !important;
    width: 10.9%;
  }
}

.home_ctr_fixed-tes-e {
  position: fixed;
  top: 21px !important;
  right: 20px !important;
  left: auto !important;
  width: 4%;

  @media(max-width: $tablet) {
    top: 18px !important;
    right: 18px !important;
    width: 10.9%;
  }
}

.home_ctr_fixed-tes-s {
  position: fixed;
  top: auto !important;
  right: 20px !important;
  bottom: 18px !important;
  left: auto !important;
  width: 4%;

  @media(max-width: $tablet) {
    right: 18px !important;
    bottom: 15px !important;
    width: 10.9%;
  }
}

.home_ctr_fixed-tes-name {
  position: fixed;
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: 20px !important;
  width: 100px;
  height: 70px !important;
  font-size: 12px;
  text: {
    align: left;
    transform: uppercase;
  }
  letter-spacing: 0.1em;
}

.home_ctr_gradient {
  position: fixed;
  width: 1%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 0;
}

.home_ctr_second-panel {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: $brandRed;
  z-index: 0;

  @media(max-width: $tablet) {
    height: auto;
    min-height: 100vh;
    padding-bottom: 80px;
  }
}

.midnight_ctr,
.midnight_ctr_tes-full-text {
  overflow: visible !important;
  z-index: 1000;
}

.midnight_ctr_tes-full-text {
  height: 85px;
}

.midnightInner {
  overflow: hidden !important;
}

.midnightHeader.red {
  color: $black;

  svg path {
    fill: $brandRed;

    &.st1 {
      fill: $brandGrey;
    }
  }
}

.midnightHeader.white {
  color: $brandGrey;

  svg path {
    fill: $brandGrey;

    &.st1 {
      fill: $brandRed;
    }
  }
}

.home_ctr_info-text {
  width: 80%;
  font-size: 2vh;
  color: $brandGrey;
  text: {
    align: left;
    //transform: uppercase;
  }
  line-height: 1.1em;
  margin: 0 auto;
  padding: 200px 0 0;

  @media(max-width: $tablet) {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 70%;
    font-size: 12px;
    padding: 0;
    transform: translate(-50%, -50%);
  }

  @media(max-width: $phone) {
    font-size: 11px;
  }

  @media(max-width: $smallPhone) {
    font-size: 9px;
  }
}

.home_ctr_logo-ctrl {
  width: 80%;
  text-align: left;
  margin: 0 auto;

  @media(max-width: $tablet) {
    position: absolute;
    bottom: 15vh;
    left: 50%;
    width: 70%;
    padding: 0;
    transform: translateX(-50%);
  }

  a:hover {
    opacity: 0.5;
  }

  img {
    width: 200px;
    margin-right: 30px;

    @media(max-width: $tablet) {
      width: 80px;
      margin-right: 10px;
    }

    @media(max-width: $smallPhone) {
      width: 50px;
      margin-right: 5px;
    }
  }
}

.home_img_againity {
  width: 180px !important;
  vertical-align: -15px;

  @media(max-width: $tablet) {
    width: 70px !important;
    vertical-align: -8px;
  }

  @media(max-width: $smallPhone) {
    width: 50px !important;
    vertical-align: -4px;
  }
}

.home_btn_about {
  margin: {
    top: 20px;
    bottom: 40px;
    left: 0 !important;
  }

  @media(max-width: $smallPhone) {
    margin-bottom: 30px;
  }
}

.home_lnk_about-more {
  display: inline-block;
  font-size: 2vh;
  color: white;
  text: {
    decoration: underline;
    transform: uppercase;
  }
  margin-bottom: 30px;

  &:hover {
    font-style: italic;
    color: white;
    opacity: 1 !important;
  }

  @media(max-width: $tablet) {
    font-size: 12px;
  }

  @media(max-width: $phone) {
    font-size: 11px;
  }

  @media(max-width: $smallPhone) {
    font-size: 9px;
    margin-bottom: 30px;
  }
}

.debug {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
}

.home_txt_lang {
  position: fixed;
  left: 42px !important;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: left;
  opacity: 0;
  cursor: pointer;

  @media(max-width: $tablet) {
    left: 25px !important;
  }

  .default {
    height: 20px !important;
    margin-top: -20px !important;
  }

  &._mod_lt {
    top: calc(50% - 120px) !important;
  }

  &._mod_en {
    top: calc(50% + 50px) !important;
  }
}

.home_ctr_logo-btn-break {
  display: none;

  @media(max-width: $tablet) {
    display: inline-block;
  }
}
