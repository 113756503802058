.contact_ctr {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: $brandRed;
  color: $brandGrey;
}

.contact_ctr_content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  font-size: 2.6vh;
  line-height: 1.2em;
  transform: translate(-50%, -50%);

  @media(max-width: $tablet) {
    width: 70%;
    font-size: 13px;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      font-style: italic;
    }
  }
}

.contact_txt_info {
  text-transform: uppercase;
}

.contact_lnk_email {
  margin-bottom: 40px;

  a {
    font-size: 4.8vh;
    color: $white;
    text: {
      transform: uppercase;
      decoration: underline;
    }

    &:hover {
      font-style: italic;
    }
  }
}

.contact_ctr_social {
  margin: 40px 0;

  a {
    &:hover {
      opacity: 0.5;
    }
  }

  img {
    width: 50px;
  }
}

.contact_lnk_privacy {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  a {
    font-size: 2vh;
    color: $brandGrey;
    text: {
      transform: uppercase;
      decoration: underline;
    }

    &:hover {
      font-style: italic;
    }
  }
}

.contact_ctr_copyright {
  position: absolute;
  bottom: 19px;
  left: 50%;
  font-size: 10px;
  line-height: 10px;
  transform: translateX(-50%);

  span {
    font-size: 18px;
    vertical-align: -7px;
  }
}
