.title_ctr {
  position: fixed;
  top: 30px !important;
  left: 50% !important;
  width: 150px;
  transform: translateX(-50%);
  opacity: 0;
  z-index: 100000;

  @media(max-width: $tablet) {
    top: 14px !important;
  }
}

svg {

  .st0 {
    //fill: $white;
    overflow: hidden;
  }

  .scroll {
    fill: $white;
  }

  .st1,
  .noFill {
    fill: transparent !important;
  }

  @media(max-width: $tablet) {
    transform: scale(0.9);
  }

  image {
    overflow:hidden;
    opacity:0.75;
  }

  text {
    font: 20px $brandText;
    color: $black;
    text-transform: uppercase;
  }
}
