.consumption_ctr_animation {
  .cls-1,.cls-2{fill:none;}.cls-1{stroke:#000;stroke-miterlimit:10;stroke-width:2px;}.cls-3{fill:url(#linear-gradient);}.cls-4{fill:url(#linear-gradient-2);}.cls-5{fill:url(#linear-gradient-3);}.cls-6{fill:url(#linear-gradient-4);}
}

.consumption_ctr_arrowhead-lt {
  opacity: 0;
}

.consumption_ctr {
  background-color: $brandGrey;
}

.consumption_ctr_backing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 112000;
}

.consumption_ctr_animated-title {
  margin-bottom: 50px;

  .cls-1{font-size:47.21px;}.cls-2{fill:none;stroke:#000;stroke-miterlimit:10;}

  svg {
    display: block;
    height: 6vh;
    margin: -80px auto 0;

    @media(max-width: $tablet) {
      margin-top: -40px;
    }
  }
}

.consumption_svg_anim-text-bg {
  fill: $brandGrey;
}

.consumption_svg_label-min,
.consumption_svg_label-max {
  cursor: pointer;
}
