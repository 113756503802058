.privacy_ctr {
  width: 100%;
  min-height: 100vh;
  background-color: $brandGrey;
}

.privacy_ctr_content {
  width: 80%;
  font-size: 2vh;
  text-align: left;
  margin: 0 auto;
  padding: 80px 0;

  @media(max-width: $tablet) {
    width: 90%;
    font-size: 12px;
    padding: 20px;
  }
}

.privacy_txt_title {
  font-size: 32px;

  @media(max-width: $tablet) {
    font-size: 24px;
    line-height: 1.2em;
  }
}

.privacy_ctr_close {
  text-align: right;
}
